import { styled, TDefaultTheme } from 'react-ui-kit-exante';

interface CounterProps {
  theme?: TDefaultTheme;
  isActive: boolean;
}

export const Counter = styled('span')(({ theme, isActive }: CounterProps) => ({
  padding: '1px 10px',
  borderRadius: '20px',
  border: `2px solid ${
    isActive ? theme?.color?.typo?.primary : theme?.color?.typo?.action
  }`,
  '&:hover': {
    border: `2px solid ${theme?.color.typo.promo}`,
  },
}));
