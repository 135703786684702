import { styled, TDefaultTheme, CloseButton } from 'react-ui-kit-exante';

export const Wrapper = styled('div')(({ theme }) => ({
  padding: '36px 24px 24px',
  background: theme?.color?.bg?.primary,
}));

export const DateWithProgressBar = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledDate = styled('h1')(({ theme }) => ({
  margin: '0',
  color: theme?.color?.typo?.primary,
  font: '400 32px / 1 FoxSansPro, sans-serif',
}));

export const ProgressBar = styled('div')(() => ({
  width: '73%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

interface LinearProgressWrapperProps {
  theme?: TDefaultTheme;
  isFinish: boolean;
}

export const LinearProgressWrapper = styled('div')(
  ({ theme, isFinish }: LinearProgressWrapperProps) => ({
    width: '96%',
    '& > .MuiLinearProgress-root': {
      height: '8px',
      borderRadius: '20px',
      backgroundColor: '#F4F5F6',
      '& > .MuiLinearProgress-bar': {
        backgroundColor: isFinish ? theme?.palette?.success?.main : '#EA9C0B',
      },
    },
  }),
);

export const Statistic = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, min-content)',
  backgroundColor: theme?.color?.bg?.primary,
  marginTop: '24px',
  gap: ' 32px',
  alignItems: 'baseline',
  overflowX: 'auto',
}));

export const Item = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const Value = styled('div')(({ theme }) => ({
  fontSize: '14px',
  marginBottom: '8px',
  display: 'flex',
  flexDirection: 'column',
  whiteSpace: 'nowrap',
  color: theme?.color?.typo?.primary,
}));

export const Title = styled('div')(({ theme }) => ({
  fontSize: '13px',
  whiteSpace: 'nowrap',
  color: theme?.color?.typo?.secondary,
}));

export const Percent = styled('span')(() => ({
  fontSize: '13px',
  whiteSpace: 'nowrap',
  color: '#99A9AD',
}));

export const CenterLoader = styled('span')(() => ({
  display: 'flex',
  justifyContent: 'center',
  height: '95px',
}));
